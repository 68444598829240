import React from "react"
import { Container, Row, Col } from "reactstrap"
import RenderBlock from "components/ui/block-text"
import Image from "components/ui/image"

const Footer = ({ data }) => {
  return (
    <footer>
      <Container>
        <Row>
          <Col className="text-center">
            <Image data={data?.logo} className="img-fluid padding-bottom-30" />
            <RenderBlock data={data?._rawDescription} />
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
