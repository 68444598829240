import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import classNames from 'classnames';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useMediaQueries } from '@react-hook/media-query';

import Logo from 'images/logo.png';
import LogoInverse from 'images/logo-inverse.png';

const Header = () => {
  const [sticky, setSticky] = useState(false);
  const [afterBanner, setAfterBanner] = useState(false);

  const {
    matches: { isTablet },
  } = useMediaQueries({
    isMobile: "only screen and (max-width: 580px)",
    isTablet: "only screen and (max-width: 850px) and (min-width: 581px) ",
  })

  const handleScroll = () => {
    const afterBannerEl = window.document.getElementById('section-service').offsetTop;
    if(window.scrollY > 100){
      setSticky(true);
    } else {
      setSticky(false);
    }
    if (window.scrollY > afterBannerEl) {
      setAfterBanner(true);
    } else {
      setAfterBanner(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  });

  return (
    <header className={classNames({ 'is-sticky': sticky, 'inverse': afterBanner })}>
      <Container fluid>
        <Row className="padding-top-15 padding-bottom-15 align-items-center">
          <Col xs="6" md={isTablet ? "3" : "2"} className="text-xs-center">
            <img src={afterBanner ? LogoInverse : Logo } className="img-fluid" alt="RV Staffing"/>
          </Col>
          <Col xs="6" md={{ size: 4, offset: isTablet ? 5 : 6 }} className="text-xs-center text-sm-rigth">
            <AnchorLink href="#contact-us" offset="260" className="float-right btn btn-outline-secondary">
              Contact Mission Control
            </AnchorLink>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default Header;

