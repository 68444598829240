import React from "react"
import PropTypes from "prop-types"
import Header from "components/header"
import Footer from "components/footer"

const Layout = ({ navbar, footer, children }) => (
  <section className="main--layout">
    <Header data={navbar} />
    <main>{children}</main>
    <Footer data={footer} />
  </section>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
